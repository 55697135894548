import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { Header, TopHeader , Footer, Loader, Offerslider } from '../../layouts/general';
import {Testimonials} from '../../layouts/home06'

export default class InvestmentAdvisory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Pages'
                }
            ],
            titleheading: [
                {
                    id: '1',
                    title: 'Services'
                }
            ],
            breadcrumbs: [
                {
                    id: 1,
                    title: 'Home',
                    classicon: 'fa fa-angle-right',
                    aria: 'true'
                },
                {
                    id: 2,
                    title: 'Services',
                    classicon: 'fa fa-angle-right',
                    aria: 'true'
                }
                ,
                {
                    id: 3,
                    title: 'Investment Advisory service',
                    classicon: '',
                    aria: ''
                }
            ],
            titleServices: [
                {
                    id: 1,
					title: 'Investment Advisory Services',
					description: 'Prosource Advisory aim to provide customized financial solutions to our clients according to their requirements. We depend on our rich and diverse expertise, industry experience, commitment to integrity and ethics and personalized solutions. We are armed with a team of highly qualified and expert professionals to aid you in intelligent decision making. ',
                }
			],
			imagebox: [
				{
					id: '1',
					imgsrc: 'images/imagebox/04.jpg',
					title: 'Accounting Service',
					description: 'We help you to obtain finance from nationalized or private financial institutions for purchase of machinery either to start a new operation or to modernize/expand your existing operations.'
				},
				{
					id: '2',
					imgsrc: 'images/imagebox/05.jpg',
					title: 'GST Registration and advisory service',
					description: 'A businessman requires finance for purchase of factory building/shed or for construction of factory building. Various institutions and banks provide finance varying from 60% to 80% of cost of building'
				},
				{
					id: '3',
					imgsrc: 'images/imagebox/06.jpg',
					title: 'Income tax',
					description: 'Analysing requirement of the organization and studying financial documents we can help to obtain required working capital finance in the form of CC (Cash credit), LC ( letter of credit), OD (over draft), BG ( Bank guarantee)'
				},
				{
					id: '4',
					imgsrc: 'images/imagebox/07.jpg',
					title: 'TDS service',
					description: 'As MSME is the segment on which the economy of any country is based Government gives various types of financial assistance (subsidy) to MSME units.'
				}
			]
        }
        const isAuthenticated = localStorage.getItem('isAuthenticated')
        if(isAuthenticated === true){
            setInterval(() => {
                localStorage.setItem('isAuthenticated', false);
                window.location.reload(false);
              }, 300000);
        }
    }
    render() {
        return (
            <div className="bg-body3">
                <div className="boxed">
                    <Loader />
                    {/* <TopHeader /> */}
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }
                    

                    {/* <div className="page-title">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="page-title-heading">
                                        <h1 className="h1-title">Investment Advisory service</h1>
                                    </div>
                                    <ul className="breadcrumbs">
                                        {
                                            this.state.breadcrumbs.map(data =>(
                                                <li key={data.id} ><Link to="#" title="">{data.title}<i className={data.classicon} aria-hidden={data.aria}></i></Link></li>
                                            ))
                                        }
                                    </ul>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <Offerslider />

                    <section className="flat-row pd-services-post">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							{
								this.state.titleServices.map(data =>(
									<div className="title-section center s1" key={data.id} >
										<h2>{data.title} </h2>
										<p className="sub-title-section">{data.description} </p>
									</div>
								))
							}
							<div className="dividers dividers-imagebox"></div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							{/* <div className="wrap-imagebox-grid">
									{
										this.state.imagebox.map(data =>(
											<div className="flat-imagebox services-grid item" key={data.id} >
												<div className="flat-imagebox-inner"  >
													<div className="flat-imagebox-image">
														<img src={data.imgsrc} alt="img" />
													</div>
													<div className="flat-imagebox-header">
														<h3 className="flat-imagebox-title">
															<Link to="#">{data.title}</Link>	
														</h3>
													</div>
													<div className="flat-imagebox-content">
														<div className="flat-imagebox-desc">{data.description}</div>
														<div className="flat-imagebox-button">
															<Link to="#" target="_blank">Read More</Link>
														</div>
													</div>
												</div>
											</div> 
								
										))
									} 
							</div> */}
						</div>
					</div>
				</div>
			</section>


                    {/* <Testimonials /> */}
                    
                    <Footer />
                </div>
            </div>
        );
    }
}
