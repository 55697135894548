import React, { Component } from 'react';
import Qcard from './QuickConnect';
import Qcard2 from '../Quickconnect2';

class TopFooter extends Component {
  render() {
    return (
      <section id="quick-connect" class="index-3">
        <div class="container">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="row">

              <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="https://goo.gl/maps/71UhP79eJFzczFqh8" rel="noreferrer" target="_blank" title="">
                <div class="newsletter">
                  <div class="icon2" style={{ textAlign: 'center' }}> <i class="fa fa-map-marker" aria-hidden="true"></i> </div>
                  <Qcard
                      Sheading='Address'
                      hcontent='506 507 central Business Space opp HDFC Bank Usmanpura Ahmedabad-13'
                    />
                </div>
                </a>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class='quick-info'>
                  <div class="icon2" style={{ textAlign: 'center' }}> <i class="fa fa-phone" aria-hidden="true"></i> </div>
                  <Qcard2 Sheading='Call Us On' 
                  />
                  <a href="">
                  <Qcard2 
                    hcontent='Call Main Office : +918980100800'
                  />
                  </a>
                  <a href="https://wa.me/918980100800" rel="noreferrer" target="_blank" title="" >
                  <Qcard2 
                    hscontent='Whats App On : +918980100800'
                  />
                  </a>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="newsletter">
                  <div class="icon2" style={{ textAlign: 'center' }}> <i class="fa fa-envelope" aria-hidden="true"></i>  </div>
                  <Qcard
                    Sheading='Mail Us On'
                    hcontent='Customer Support : support@Prosource.com'
                  />

                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      /* <!-- /.widget-infomation --> */
    );
  }
}

export default TopFooter;