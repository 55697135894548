import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { Header, TopHeader, Footer, Loader, Offerslider } from '../../layouts/general';
import { Testimonials } from '../../layouts/home06'

export default class RegistrationFormation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			headers: [
				{
					id: 1,
					names: 'Pages'
				}
			],
			titleheading: [
				{
					id: '1',
					title: 'Services'
				}
			],
			breadcrumbs: [
				{
					id: 1,
					title: 'Home',
					classicon: 'fa fa-angle-right',
					aria: 'true'
				},
				{
					id: 2,
					title: 'Services',
					classicon: 'fa fa-angle-right',
					aria: 'true'
				}
				,
				{
					id: 3,
					title: 'Registration / Formation Services',
					classicon: '',
					aria: ''
				}
			],
			titleServices: [
				{
					id: 1,
					title: 'Registration / Formation Services',
					description: 'Prosource Advisory aim to provide customized financial solutions to our clients according to their requirements. We depend on our rich and diverse expertise, industry experience, commitment to integrity and ethics and personalized solutions. We are armed with a team of highly qualified and expert professionals to aid you in intelligent decision making. ',
				}
			],
			imagebox: [
				{
					id: '1',
					imgsrc: 'images/imagebox/02.jpg',
					title: 'MSME Registration',
					description: 'We help you to obtain finance from nationalized or private financial institutions for purchase of machinery either to start a new operation or to modernize/expand your existing operations.'
				},
				{
					id: '2',
					imgsrc: 'images/imagebox/03.jpg',
					title: 'NSIC Registration',
					description: 'A businessman requires finance for purchase of factory building/shed or for construction of factory building. Various institutions and banks provide finance varying from 60% to 80% of cost of building'
				},
				{
					id: '3',
					imgsrc: 'images/imagebox/04.jpg',
					title: 'Pvt ltd Company/Firm Registration',
					description: 'Analysing requirement of the organization and studying financial documents we can help to obtain required working capital finance in the form of CC (Cash credit), LC ( letter of credit), OD (over draft)'
				},
				{
					id: '4',
					imgsrc: 'images/imagebox/05.jpg',
					title: 'PF/ESI Registration',
					description: 'As MSME is the segment on which the economy of any country is based Government gives various types of financial assistance (subsidy) to MSME units.'
				},
				{
					id: '5',
					imgsrc: 'images/imagebox/06.jpg',
					title: 'Trademark Registration',
					description: 'Mutual funds pool money from many investors to purchase broad range of investments, such as stocks.'
				},
				{
					id: '6',
					imgsrc: 'images/imagebox/07.jpg',
					title: 'Food department registration',
					description: 'Mutual funds pool money from many investors to purchase broad range of investments, such as stocks.'
				},
				{
					id: '7',
					imgsrc: 'images/imagebox/08.jpg',
					title: 'Shops & establishment (GumastaRegistration)',
					description: 'Mutual funds pool money from many investors to purchase broad range of investments, such as stocks.'
				},
				{
					id: '8',
					imgsrc: 'images/imagebox/01.jpg',
					title: 'Professional tax registration',
					description: 'Mutual funds pool money from many investors to purchase broad range of investments, such as stocks.'
				}
			],
			imagebox2: [
				{
					id: '1',
					imgsrc: 'images/imagebox/07.jpg',
					title: 'Registration formalities at a very attractive rate.',
				},
				{
					id: '2',
					imgsrc: 'images/imagebox/09.jpg',
					title: 'All types of formation and registration available.',
				}

			]
		}
		const isAuthenticated = localStorage.getItem('isAuthenticated')
        if(isAuthenticated === true){
            setInterval(() => {
                localStorage.setItem('isAuthenticated', false);
                window.location.reload(false);
              }, 300000);
        }
	}
	render() {
		return (
			<div className="bg-body3">
				<div className="boxed">
					<Loader />
					{/* <TopHeader /> */}
					{
						this.state.headers.map(data => (
							<Header data={data} key={data.id} />
						))
					}

					<Offerslider />

					<section className="flat-row pd-services-post">
						<div className="container">
							<div className="row">
								<div className="col-md-12">
									<div className="title-section center s1"  >
										<h2>Why Us</h2>

									</div>
									<div className="dividers dividers-imagebox"></div>
								</div>
								<section className="flat-row pd-services-post">
									<div className="container">
										<div className="row">
											<div className="col-md-12">
												<div className="wrap-imagebox-grid">
													{
														this.state.imagebox2.map(data => (
															<div className="flat-imagebox services-grid item" key={data.id} >
																<div>
																	<div class="rfcard">
																		<div class="col-md-6">
																			<div class="box2" style={{ backgroundImage: "url(" + data.imgsrc + ")", }}>
																				<div class="hover">
																					<h1>{data.title}</h1>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>


														))
													}

												</div>
											</div>
										</div>
									</div>
								</section>
							</div>
							<div className="row">
								<div className="col-md-12">
									{
										this.state.titleServices.map(data => (
											<div className="title-section center s1" key={data.id} >
												<h2>{data.title} </h2>
												{/* <p className="sub-title-section">{data.description} </p> */}
											</div>
										))
									}
									<div className="dividers dividers-imagebox"></div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12">
									<div className="wrap-imagebox-grid">
										{
											this.state.imagebox.map(data => (
												<div className="flat-imagebox services-grid item" key={data.id} >
													<div>
														<div class="rfcard">
															<div class="col-md-4">
																<div class="box2" style={{ backgroundImage: "url(" + data.imgsrc + ")", }}>
																	{/* <img src={data.imgsrc} /> */}
																	<div class="hover">
																		<h1>{data.title}</h1>
																		<p>{data.description}</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>


											))
										}

									</div>
								</div>
							</div>
						</div>
					</section>


					{/* <Testimonials /> */}

					<Footer />
				</div>
			</div>
		);
	}
}
