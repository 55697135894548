import React, { Component } from 'react';
import BottomBar from './BottomBar';
import { UsefulLink,TextMainFooter } from './footers/index';
import { BlogSlidebar } from './blog/index';

class Footer extends Component {
    render() {
        return (
            <div>
            <footer id="footer">
                <div class="footer-widgets">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                {/* <TopFooter /> */}
                            </div>
                            {/* <!-- /.col-md-12 --> */}
                        </div>
                        {/* <!-- /.row --> */}
                        <div class="row widget-box">
                            <div class="col-md-4 col-xs-12">
                                <TextMainFooter />
                            </div>
                            {/* <!-- /.col-md-4 --> */}
                            <div class="col-md-4 col-xs-12">
                                <UsefulLink />
                            </div>
                            {/* <!-- /.col-md-4 --> */}
                            <div class="col-md-4 col-xs-12">
                                <BlogSlidebar />
                            </div>
                            {/* <!-- /.col-md-4 --> */}
                        </div>
                        {/* <!-- /.row .widget-box --> */}
                    </div>
                    {/* <!-- /.container --> */}
                </div>
                {/* <!-- /.footer-widgets --> */}
                <BottomBar />
                {/* footer-bottom  */}
            </footer>
           
            </div>
            // <!-- /#footer -->
        );
    }
}

export default Footer;