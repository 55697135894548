import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import menus from '../menu';
import userMenu from '../userMenu';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            linklogo: '/',
            user: localStorage.getItem('user'),
            isAuthenticated:localStorage.getItem('isAuthenticated'),
        }
        this.handleLogout = this.handleLogout.bind(this)
        console.log("user", this.state.user,this.state.isAuthenticated)
    }

    handleLogout = () => {
        // console.log('click')
        window.localStorage.clear();
        localStorage.setItem('isAuthenticated',false)
        window.location.href = "/"
    }

    render() {
        const { location } = this.props;
        return (
            <div id="header" class="header bg-color fixed">
                <div class="container">
                    <div class="row">
                        <div class="header-wrap">
                            <div class="col-md-3">
                                <div id="logo" class="logo">
                                    <Link to="/" onClick={() => { window.location.href = this.state.linklogo }}><img src="images/Prosource_logo.png" alt="financial"
                                        data-retina="images/logo-blog.png" /></Link>
                                </div>
                                {/* logo*/}
                            </div>
                            {/* .col-md-2  */}
                            <div class="col-md-9">
                                
                                {/* <!-- /.flat-show-search -->*/}
                                <div class="nav-wrap">
                                    <div class="btn-menu">
                                        <span></span>
                                    </div>
                                    {/* <!-- //mobile menu button -->*/}
                                    <nav id="mainnav" class="mainnav">
                                        <ul class="menu">
                                            {
                                                menus.map(data => (
                                                    <li class={data.name === this.props.data.names ? "active" : ""} key={data.id}>
                                                        <Link to={data.linkmenu} onClick={() => { window.location.href = data.linkmenu }}>{data.name} {data.name === 'Services' ? <i class="fa fa-angle-down" aria-hidden="true"></i> : ""} </Link>
                                                        {
                                                            data.namesub === undefined ? "" :
                                                                <ul class="sub-menu">
                                                                    {
                                                                        data.namesub.map(submenus => (
                                                                            <li class={location.pathname === submenus.links || submenus.sub === this.props.data.names02 ? "active" : ""} key={submenus.id}><Link to={submenus.links} onClick={() => { window.location.href = submenus.links }}>{submenus.sub}</Link>
                                                                                {
                                                                                    submenus.submenu === undefined ? "" :
                                                                                        <ul class="sub-menu">
                                                                                            {
                                                                                                submenus.submenu.map(menusub => (
                                                                                                    <li class={location.pathname === menusub.linksub ? "active" : ""} key={menusub.id}><Link to={menusub.linksub} onClick={() => { window.location.href = menusub.linksub }}>{menusub.titlesub}</Link></li>
                                                                                                ))
                                                                                            }
                                                                                        </ul>
                                                                                }
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                        }
                                                    </li>
                                                ))
                                            }
                                            <li >
                                                <div>
                                            
                                                    {this.state.isAuthenticated !== 'false' ?
                                                        <nav class="mainnav">
                                                            <ul class="menu">
                                                                {
                                                                    userMenu.map(data => (
                                                                        <li key={data.id}>
                                                                            <Link to="">{this.state.user} <i class="fa fa-angle-down" aria-hidden="true"></i></Link>
                                                                            <ul class="sub-menu">
                                                                                <li>
                                                                                    <Link to="/profile" onClick={() => { window.location.href = "/profile" }}>
                                                                                        Profile
                                                                                    </Link>
                                                                                </li>
                                                                                <li>
                                                                                    <Link onClick={this.handleLogout}>
                                                                                        Logout
                                                                                    </Link>
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </nav>
                                                        :
                                                       
                                                            <ul class="menu">
                                                                <li>
                                                        <Link to="/login" onClick={() => { window.location.href = "/login" }}>Login / Signup</Link></li>
                                                        </ul>
                                                        

                                                    }
                                                </div>

                                            </li>
                                        </ul>

                                        {/* <!-- /.menu -->*/}
                                    </nav>
                                    {/* <!-- /#mainnav -->*/}
                                </div>
                                {/* <!-- /.nav-wrap --> */}
                            </div>
                            {/* <!-- /.col-md-9 --> */}
                        </div>
                        {/* <!-- /.header-wrap --> */}
                    </div>
                    {/* <!-- /.row --> */}
                </div>
                {/* <!-- /.container --> */}
            </div>
            // {/* <!-- /header --> */}



        );
    }
}

export default withRouter(Header);