import React, { Component } from 'react';
import { Link } from "react-router-dom";
class Benefit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			itembox: [
				{
					id: 1,
					title: 'Loan Consultancy',
					classicon: 'icon-benefit',
					boxcontent: 'In order to cater to all needs of the business units at single place, we provide different financial services.',
					link:'/loanconsultancy'
				},
				{
					id: 2,
					title: 'Subsidy Consultancy',
					classicon: 'icon-benefit options',
					boxcontent: 'As MSME is the segment on which the economy of any country is based Government gives various types of financial assistance (subsidy) to MSME units',
					link:'/subsidyconsultancy'
				},
				{
					id: 3,
					title: 'Registration / Formation Services',
					classicon: 'icon-benefit people',
					boxcontent: `MSME Registration
					NSIC Registration
					Pvt ltd Company/Firm Registration
					PF/ESI Registration
					Trademark Registration
					Food department registration`,
					link:'/registrationformation'
				},
				{
					id: 4,
					title: 'Book Keeping services',
					classicon: 'icon-benefit caculator',
					boxcontent: 'Accounting Services , GST Registration and advisory services, Income tax, TDS services',
					link:'/bookkeeping'
				},
				{
					id: 5,
					title: 'Investment Advisory services',
					classicon: 'icon-benefit magic',
					boxcontent: 'Prosource Advisory aim to provide customized Investment Advisory to our clients according to their requirements.',
					link:'/investmentadvisory'
				},
				{
					id: 6,
					title: 'NRI services',
					classicon: 'icon-benefit global',
					boxcontent: 'Company Formation (start up), Book keeping, Taxation Advisory, Business Development & investment consultancy, Registrations',
					link:'/nriservice'
				},
			]
		}
	}
	render() {
		return (
			<div>
			<section class="flat-row parallax parallax1 flat-benefit ">
				<div class="container">
					<div class="row">
					

						<div class="col-md-4">
							<div class="benefit-image">
								<img src="./images/benefit/01.png" alt="financial" />
							</div>
						</div>

						<div class="col-md-8">
								<div class="title-section left">
										<h2>Our Services</h2>
									</div>
								{
									this.state.itembox.map(data => (
										<div class="col-md-4"><Link to={data.link} onClick={() => { window.location.href = data.link }}>
										<section class="content" >
											<div class="cards">
												<div class="card">
													<div class="card__side card__side--front card__side--front-1 iconbox style3">
													<div class="box-header flip-card-front">
										 			<div class={data.classicon}>

													</div>
										 			<div class="box-title">
														<Link to="" title="">{data.title}</Link>
													</div>
												</div>
													</div>
													<div class="card__side card__side--back card__side--back-1">
														<div class="flip-card-back">
															{data.boxcontent}
														</div>
													</div>
												</div>
											</div>
										</section></Link>
										</div>
									))
								}
						</div>
					</div>
				</div>
			</section>
			</div>


		);
	}
}

export default Benefit;