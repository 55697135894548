import React, { Component } from 'react'
import { Header, TopHeader, Footer, Loader } from '../layouts/general';
import { Link } from 'react-router-dom';
import axios from 'axios';

export default class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Home'
                }
            ],
            data: {},
            message: '',
            errMessage: '',
            succesmsg: false,
            err: false,
            err1: false

        }
        this.handleChange = this.handleChange.bind(this);
        this.userSignup = this.userSignup.bind(this)
    }


    handleChange = (event) => {
        this.setState({ ...this.state, [event.target.id]: event.target.value });

    };

    userSignup = (event) => {
        event.preventDefault();
        const data = { first_name: this.state.fname, last_name: this.state.lname, phone: this.state.number, email: this.state.email, password: this.state.password }
        const headers = {
            'Content-Type': 'multipart/form-data'
        }
        const baseURL = "https://vibrantsofttech.com/project/prosourceadvisory/api/authentication/registration/";


        switch (true) {
            case this.state.fname === undefined:
                this.setState({
                    err: 'fname'
                })
                break;
            case this.state.lname === undefined:
                this.setState({
                    err: 'lname'
                })
                break;
            case this.state.number === undefined:
                this.setState({
                    err: 'phone'
                })
                break;
            case this.state.email === undefined:
                this.setState({
                    err: 'email'
                })
                break;
            case this.state.password === undefined:
                this.setState({
                    err: 'password'
                })
                break;
            case this.state.password.length < 8:
                this.setState({
                    err: true
                })
                break;
            default:
                //otherwise perform your logic

                

                axios
                    .post(baseURL, data, {
                        headers: headers
                    })
                    .then((response) => {
                        if (response.data.status === true) {
                            this.setState({
                                message: 'Thanks for registration plese check your email and verify it!',
                                succesmsg: true,
                                showmsg:false
                            })
                            setTimeout(() => {
                                window.location.href = "/login"
                            }, 10000);
                        } else {
                            this.setState({
                                message: response.data.message,
                                showmsg: true
                            })
                        }
                    });
                break;
        }



    }

    render() {
        return (
            <div className="bg-body3">
                <div className="boxed">
                    <Loader />
                    {/* <TopHeader /> */}
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id} />
                        ))
                    }

                    <div class="login-container">
                        <div class="col-md-6 d-none d-md-block">
                            <img src="images/signup.jpeg" class="img-fluid" style={{ minHeight: "80%", marginTop: '75px' }} />
                        </div>
                        <div class="login-form">
                            <div class="login-form-inner col-sm-12">
                                <div class="logo"> <h1 class="body-text">Welocome to Prosource!</h1>
                                </div>
                                <h1 style={{ color: 'black', fontSize: '30px' }}>Register!</h1>
                                {this.state.showmsg === true ? <h4 style={{ color: 'red', marginBottom: '10px' }}>{this.state.message}</h4> : ""}
                                {this.state.succesmsg === true ? <p style={{ color: '#18ba60', marginBottom: '10px', fontSize: '17px' }}>{this.state.message}</p> : ""}
                                <div style={{ marginBottom: "0px" }} class="login-form-group">
                                    <label for="fname">First Name<span class="required-star">*</span></label>
                                    <input type="text" onChange={this.handleChange} placeholder="Enter First Name" id="fname" />
                                    {this.state.err === 'fname' ? <h7 style={{ color: 'red', marginBottom: '10px' }}>Please enter First Name</h7> : ""}
                                </div>
                                <div style={{ marginBottom: "0px" }} class="login-form-group">
                                    <label for="lname">Last Name<span class="required-star">*</span></label>
                                    <input type="text" onChange={this.handleChange} placeholder="Enter Last Name" id="lname" />
                                    {this.state.err === 'lname' ? <h7 style={{ color: 'red', marginBottom: '10px' }}>Please enter Last Name</h7> : ""}
                                </div>
                                <div style={{ marginBottom: "0px" }} class="login-form-group">
                                    <label for="phone">Phone Number<span class="required-star">*</span></label>
                                    <input type="number" onChange={this.handleChange} placeholder="Enter Contact Number" id="number" />
                                    {this.state.err === 'phone' ? <h7 style={{ color: 'red', marginBottom: '10px' }}>Please enter Phone Number</h7> : ""}
                                </div>
                                <div style={{ marginBottom: "0px" }} class="login-form-group">
                                    <label for="email">Email address <span class="required-star">*</span></label>
                                    <input type="text" onChange={this.handleChange} placeholder="Enter Your E-Mail" id="email" />
                                    {this.state.err === 'email' ? <h7 style={{ color: 'red', marginBottom: '10px' }}>Please enter Email Address</h7> : ""}
                                </div>
                                <div style={{ marginBottom: "0px" }} class="login-form-group">
                                    <label for="password">Password <span class="required-star">*</span></label>
                                    <input onChange={this.handleChange} type="password" placeholder="Minimum 8 characters" id="password" />
                                    {this.state.err === 'password' ? <h7 style={{ color: 'red', marginBottom: '10px' }}>Please enter Password</h7> : ""}
                                </div>

                                <a href="#" onClick={this.userSignup} className="rounded-button login-cta">SignUp</a>

                            </div>

                        </div>
                    </div>



                    <Footer />
                </div>
            </div>
        )
    }
}
