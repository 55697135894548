  
import React from 'react'

  const Qcard2= ({Sheading,hcontent,hscontent}) =>{
    return (
    <>
            <div className="section_heading">
                <h4>{Sheading}</h4>
                <div className="separator">
                  <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
                <div className="heading_content2">{hcontent}</div>
                <div className="heading_content2">{hscontent}</div>
            </div>
    </>
  );
  }

  export default Qcard2;