import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { Header, TopHeader, Footer, Loader, Offerslider } from '../../layouts/general';
import { Testimonials } from '../../layouts/home06'

export default class LoanConsultancy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMore: false,
            clickId: '',
            headers: [
                {
                    id: 1,
                    names: 'Pages'
                }
            ],
            titleheading: [
                {
                    id: '1',
                    title: 'Services'
                }
            ],
            breadcrumbs: [
                {
                    id: 1,
                    title: 'Home',
                    classicon: 'fa fa-angle-right',
                    aria: 'true'
                },
                {
                    id: 2,
                    title: 'Services',
                    classicon: 'fa fa-angle-right',
                    aria: 'true'
                },
                {
                    id: 3,
                    title: 'Loan Consultancy',
                    classicon: '',
                    aria: ''
                }
            ],
            titleServices: [
                {
                    id: 1,
                    title: 'Loan Consultancy Services',
                    description: 'Prosource Advisory aim to provide customized financial solutions to our clients according to their requirements. We depend on our rich and diverse expertise, industry experience, commitment to integrity and ethics and personalized solutions. We are armed with a team of highly qualified and expert professionals to aid you in intelligent decision making. ',
                }
            ],
            imagebox: [
                {
                    id: '1',
                    imgsrc: 'images/imagebox/04.jpg',
                    title: 'Machinery Loan',
                    description: 'We help you to obtain finance from nationalized or private financial institutions for purchase of machinery either to start a new operation or to modernize/expand your existing operations',
                    secondDescription: 'With the continuous evolution of technology, it has become imperative for manufacturing enterprises to have the latest equipment and tools that are vital to their operations. Prolonged use of obsolete machinery can be detrimental to business interests and presents an opportunity for competitors to overtake. If your operations are in need of better machines and kits to improve productivity and product quality, do not let the high costs deter you from investing in them.Financial institutions generally offer loan upto 75% of cost of machinery, tools and other accessories.  This loan can be availed for the period of 5 years or 7 years. Means one can repay this term loan by 60 monthly installments or 84 monthly installments. Rate of interest on loan varies from 9% to 11% depending on profile and rating of the borrower and policy of the bank. Our team Analyze your need and accordingly suggest the best possible options of Finance.'
                },
                {
                    id: '2',
                    imgsrc: 'images/imagebox/05.jpg',
                    title: 'Shed/Factory/Building Loan',
                    description: 'A businessman requires finance for purchase of factory building/shed or for construction of factory building. Various institutions and banks provide finance varying from 60% to 80% of cost of building',
                    secondDescription: 'Our team analyses requirement of businessman and helps to obtain maximum finance with minimum rate of interest from financial institutions. Keeping in mind various policies of banks and financial institutions regarding funding for building it becomes very much necessary to properly understand requirement and to determine most beneficial way of getting finance to fulfill requirement of a businessman. Various policies of Government regarding eligibility for subsidy is also required to be kept in mind while determining the funding method for infrastructure of the business.'
                },
                {
                    id: '3',
                    imgsrc: 'images/imagebox/06.jpg',
                    title: 'C/C – working capital',
                    description: 'A Working Capital Loan is a loan to help businesses fund their day-to-day or short-term operations. A working capital business loan can be used for various purpose.like purchase of inventory',
                    secondDescription: 'Pay for overhead costs like electricity, rent, salaries and other utilities, Finance blocked payments from debtors, Pay suppliers in advance etc.Working capital finance is available to manufacturing as well as trading concern. It can be an existing unit or it can be a new one. After analysing requirement of the organization and studying financial documents we can help to obtain required working capital finance in the form of CC (Cash credit), LC ( letter of credit), OD (over draft), BG ( Bank guarantee).'
                },
                {
                    id: '4',
                    imgsrc: 'images/imagebox/07.jpg',
                    title: 'Mortgage loan',
                    description: 'As MSME is the segment on which the economy of any country is based Government gives various types of financial assistance (subsidy) to MSME units.'
                },
                {
                    id: '5',
                    imgsrc: 'images/imagebox/08.jpg',
                    title: 'Home Loan',
                    description: 'Mutual funds pool money from many investors to purchase broad range of investments, such as stocks.'
                },
                {
                    id: '9',
                    imgsrc: 'images/imagebox/09.jpg',
                    title: 'Business Loan',
                    description: 'Mutual funds pool money from many investors to purchase broad range of investments, such as stocks.'
                },

            ],
            imagebox2: [
                {
                    id: '1',
                    imgsrc: 'images/imagebox/01.jpg',
                    title: 'Experience in Loan More than 12 years',
                },
                {
                    id: '2',
                    imgsrc: 'images/imagebox/02.jpg',
                    title: 'Team of Retired Bankers',
                },
                {
                    id: '3',
                    imgsrc: 'images/imagebox/03.jpg',
                    title: 'Effective Rate of Interest/Nationalized Bank',
                }

            ]
        }
        const isAuthenticated = localStorage.getItem('isAuthenticated')
        if(isAuthenticated === true){
            setInterval(() => {
                localStorage.setItem('isAuthenticated', false);
                window.location.reload(false);
              }, 300000);
        }
    }

    showMoreDiscripation = (id) => {
        console.log("id", id);
        this.setState({ clickId: id })
        this.setState({ showMore: !this.state.showMore })

    }

    render() {
        return (
            <div className="bg-body3">
                <div className="boxed">
                    <Loader />
                    {/* <TopHeader /> */}
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id} />
                        ))
                    }
                    <Offerslider />

                    <section className="flat-row pd-services-post">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="title-section center s1"  >
                                        <h2>Why Us</h2>

                                    </div>
                                    <div className="dividers dividers-imagebox"></div>
                                </div>
                                <section className="flat-row pd-services-post">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="wrap-imagebox-grid">
                                                    {
                                                        this.state.imagebox2.map(data => (
                                                            <div className="flat-imagebox services-grid item" key={data.id} >
                                                                <div>
                                                                    <div class="rfcard">
                                                                        <div class="col-md-4">
                                                                            <div class="box2" style={{ backgroundImage: "url(" + data.imgsrc + ")", }}>
                                                                                <div class="hover">
                                                                                    <h1>{data.title}</h1>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        ))
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    {
                                        this.state.titleServices.map(data => (
                                            <div className="title-section center s1" key={data.id} >
                                                <h2>{data.title} </h2>
                                                {/* <p className="sub-title-section">{data.description} </p> */}
                                            </div>
                                        ))
                                    }
                                    <div className="dividers dividers-imagebox"></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="wrap-imagebox-grid">
                                        {
                                            this.state.imagebox.map(data => (
                                                <div className="flat-imagebox services-grid item" key={data.id} >
                                                    <div className="flat-imagebox-inner"  >
                                                        <div className="flat-imagebox-image">
                                                            <img src={data.imgsrc} alt="img" />
                                                        </div>
                                                        <div className="flat-imagebox-header">
                                                            <h3 className="flat-imagebox-title">
                                                                <Link to="#">{data.title}</Link>
                                                            </h3>
                                                        </div>
                                                        <div className="flat-imagebox-content">
                                                            <div className="flat-imagebox-desc">{data.description}</div>
                                                            {data.id === this.state.clickId ? this.state.showMore ? <div className="flat-imagebox-desc">{data.secondDescription}</div> : "" : ""}
                                                            <div className="flat-imagebox-button">
                                                                <Link onClick={() => this.showMoreDiscripation(data.id)} to="#">Read More</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    {/* <Testimonials /> */}

                    <Footer />
                </div>
            </div>
        );
    }
}
