import React, { Component } from 'react';

import { Slider, Footer, Loader, Header } from '../layouts/general';
import { BlogFeatured, Benefit} from '../layouts/home06';
import { TopFooter } from '../layouts/general/footers/index';
// import { Callback } from "../layouts/general/callback";
// import { Link } from "react-router-dom";

class Home06 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Home'
                }
            ],
        }
        // localStorage.setItem('isAuthenticated', false)
        // window.onabort = localStorage.setItem('isAuthenticated', false);
        localStorage.setItem('isAuthenticated',false)
       const isAuthenticated = localStorage.getItem('isAuthenticated')
        if(isAuthenticated === true){
            setInterval(() => {
                localStorage.setItem('isAuthenticated', false);
                window.location.reload(false);
              }, 300000);
        }
    }

    

    render() {
        return (
            <div class="bg-body2">
                <div class="boxed">
                    <Loader />
                    {/* <TopHeader /> */}
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id} />
                        ))
                    }
                    <Slider />
                    <section class="flat-row flat-imagebox pd-imagebox-s3">
                        <div class="container">
                            <div class="row">

                                <BlogFeatured />

                            </div>
                        </div>
                    </section>
                    <Benefit />

                    {/* <Counter /> */}

                    {/* <Testimonials /> */}

                    {/* <CarouselClient /> */}

                    {/* <BlogBot /> */}

                    <TopFooter />

                    {/* <Callback /> */}

                    {/* <section class="flat-row pdmap">
                        <div class="flat-maps" data-address="Thành phố New York, Tiểu bang New York" data-height="453" data-images="images/map/map-1.png" data-name="Themesflat Map"></div>
                        <div class="gm-map">                
                            <div class="map"></div>                        
                        </div>
                    </section> */}

                    <Footer />
                    
                    <div>
                    <a href="https://wa.me/918980100800" rel="noreferrer" target="_blank" title="" class="btnwhatsapp">
                       
                        <i class="fa fa-whatsapp" style={{fontSize:30,paddingTop:12,color:'white'}}></i>
                        </a>
                    </div>
                    
                </div>
                
            </div>
        );
    }
}

export default Home06;