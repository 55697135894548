import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { TopHeader, TopBar , Footer, Loader, Header } from '../layouts/general';

import Overview from "../layouts/aboutus/Overview";
class OverView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Pages'
                }
            ],
            dataBanner: [
                {
                    id: 1,
                    classename01: 'flat-title-page parallax parallax7 style3',
                    classename02: 'container wrap-title-page ',
                    links: '/about-us',
                    names: 'About Us'
                }
            ]
        }
    }
    render() {
        return (
            <div className="bg-body3">
                <div className="boxed">
                    <Loader />
                    {/* <TopHeader /> */}
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }
                    <Overview />
                    <Footer />
                </div>
            </div>
        );
    }
}

export default OverView;