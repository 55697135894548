import React from "react";
import {BrowserRouter, Route} from 'react-router-dom'
import routes from './components/pages/index'
import IdleTimer from 'react-idle-timer';
// import { Router, Route, Switch, Redirect } from "react-router-dom";
// import { createBrowserHistory } from "history";

// "homepage": "https://prosourceadvisory.in/",
// const hist = createBrowserHistory();
function App() {

//   window.addEventListener("beforeunload", (ev) => 
// {  
//     ev.preventDefault();
//     return ev.returnValue = 'Are you sure you want to close?';
// });
  
  return (
      <BrowserRouter >
        {
          routes.map((data) => (
            <Route exact path={data.path} component={data.component}></Route>
          ))
        }
      </BrowserRouter>   
  );
}


export default App;
