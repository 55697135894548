import React, { Component } from 'react'
import { Header, TopHeader, Footer, Loader } from '../layouts/general';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';


export default class Authentication extends Component {
    constructor(props) {
        const queryParams = new URLSearchParams(window.location.search);
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Home'
                }
            ],
            showmsg: '',
            succ:false,
            err:false,
            email : queryParams.get('email'),
            token : queryParams.get('token'),
        }
        this.getToken = this.getToken.bind(this);
        this.getToken();
        localStorage.setItem('isAuthenticated', false);
    }

    getToken = () => {
        const data = { email: this.state.email, token: this.state.token }
        const headers = {
            'Content-Type': 'multipart/form-data'
        }

        const baseURL = "https://vibrantsofttech.com/project/prosourceadvisory/api/authentication/accountVerification/";

        axios
            .post(baseURL, data, {
                headers: headers
            })
            .then((response) => {
                console.log("res", response.data)
                if(response.data.status === true){
                    this.setState({
                        succ:true
                    })
                    setTimeout(() => {
                        window.location.href = "/login"
                    }, 10000);
                }else{
                    this.setState({
                        err:true,
                        showmsg:response.data.message
                    })
                }
                
            });

    }

    render() {
        return (
            <div className="bg-body3">
                <div className="boxed">
                    <Loader />
                    {/* <TopHeader /> */}
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id} />
                        ))
                    }
                    <div class="container" style={{ textAlign: 'center' }}>
                        <h1 style={{ color: '#5cb85c', marginTop: '20px' }}>Welcome To Prosource!</h1>
                        {this.state.succ ?<h1 style={{color: '#5cb85c', marginTop: '20px' }}>Your account has been verified successfully</h1> : '' }
                        {this.state.err ? <h1 style={{color: 'red', marginTop: '20px' }}>{this.state.showmsg}</h1> : '' }
                        <div>
                            <img style={{ height: '600px', width: '600px' }} src="images/auth.jpg" loading="lazy" alt="" />
                        </div>

                    </div>

                    <Footer />
                </div>

            </div >
        )
    }
}
