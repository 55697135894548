import React, { Component } from 'react'
import { Link } from 'react-router-dom'
class BlogFeatured extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datanewBox: [
                {
                    id: 1,
                    srcimg: 'images/imagebox/01.jpg',
                    title: '19+ Years of Experience.',
                    description: `We are in this business since 2001.We provides these services in most professional manner. Our team of experts always ensures that all compliances are done before 
                                    due date.We also ensure that no unnecessary cost is borne by client by way penalty, interest etc. Our taxation advice on critical points has
                                    helped many clients to save taxes in huge amount.We help you in obtaining optimum finance at 
                                    lowest cost, ensuring Government benefits and thus ultimately improving your business profits leading to your success and satisfaction.`
                },
                {
                    id: 2,
                    srcimg: 'images/imagebox/02.jpg',
                    title: 'More Than 1000+ Clients.',
                    description: `At Prosource Advisory, we don’t just create financial solutions; we create lasting relationships with our clients. A healthy customer-enterprise 
                                relationship comes with being involved with our clients and keeping them updated with the regular developments to achieve their goals.`,
                },
                {
                    id: 3,
                    srcimg: 'images/imagebox/03.jpg',
                    title: 'Company’s Motto.',
                    description: `As a financial consultant we strive to figure out optimum finance requirement of the client, evaluate various sources of availing required fund, 
                                studying and understanding various policies of financial institutions and its applicability to the client, selecting the best source of finance and ensuring 
                                lowest cost for the same. Apart from that we also study various policies of Government providing financial assistance (subsidy) to a 
                                business unit. We make full effort to see that no genuine government benefits are missed for our client`,
                },
            ]
        }
    }

    render() {
        return (
            <div>
                {
                    this.state.datanewBox.map(data => (
                        <div class="col-md-4" key={data.id} >
                            <div class="imagebox-item">
                                <div class="imagebox style3 background-color">
                                    <div class="imagebox-image">
                                        <img src={data.srcimg} alt="financial" />
                                    </div>
                                    <div class="imagebox-title">
                                        <h3><Link to="" title="">{data.title}</Link></h3>
                                    </div>
                                    <div class="imagebox-content">
                                        <div class="imagebox-desc">
                                            {data.description}
                                        </div>
                                        <div class="imagebox-desc">
                                            {data.description2}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>


        );
    }
}

export default BlogFeatured;