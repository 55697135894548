import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class UsefulLink extends Component {
    render() {
        return (
            <div className="widget widget-services">
                <div className="widget-box" style={{ color: 'white', fontSize: '20px' }}>Services</div>
                <ul className="one-half first">
                    <li><Link to="/loanconsultancy" onClick={() => { window.location.href = "/loanconsultancy" }} title="">Loan Consultancy</Link></li>
                    <li><Link to="/subsidyconsultancy" onClick={() => { window.location.href = "/subsidyconsultancy" }} title="">Subsidy Consultancy</Link></li>
                    <li><Link to="/registrationformation" onClick={() => { window.location.href = "/registrationformation" }} title="">Registration / Formation Services</Link></li>
                    <li><Link to="/bookkeeping" onClick={() => { window.location.href = "/bookkeeping" }} title="">Book Keeping services</Link></li>
                    <li><Link to="/investmentadvisory" onClick={() => { window.location.href = "/investmentadvisory" }} title="">Investment Advisory services</Link></li>
                    <li><Link to="/nriservice" onClick={() => { window.location.href = "/nriservice" }} title="">NRI services</Link></li>
                </ul>
                {/* <!-- /.one-half --> */}
                <ul className="one-half">
                    

                </ul>
                {/* <!-- /.one-half --> */}
            </div>
            /* <!-- /.widget-services --> */
        );
    }
}

export default UsefulLink;