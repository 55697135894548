import React, { Component } from 'react';
class BoxView  extends Component {
	constructor(props) {
        super(props);
        this.state = {
            imgslide: [
                {
                    id: '1',
                    srcimg: 'images/about/s01.jpg'
                },
                {
                    id: '2',
                    srcimg: 'images/about/s01.jpg'
                }
            ],
            content: [
                {
                    id: '1',
                    title: 'Want to know more about Us?',
                    description: 'Prosource Advisory aim to provide customized financial solutions to our clients according to their requirements. We depend on our rich and diverse expertise, industry experience, commitment to integrity and ethics and personalized solutions. We are armed with a team of highly qualified and expert professionals to aid you in intelligent decision making.',
                    discr:"We are in the business of project finance consultancy, subsidy and equity consultancy, financial services like Startup formation (company, llp, etc), Book keeping, Taxation Advisory and compliance, Business development consultancy, investment consultancy and different type of registrations with Government department."
                }
            ],
            blogtimeline: [
                {
                    id: '1',
                    year: 'images/startup.jpg',
                    title: 'Our Mission',
                    description: 'We are in this business since 2001 with more than 1000 satisfied customers. We provide these services in most professional manner. Our team of experts always ensures that all compliances are done before due date. We also ensure that no unnecessary cost is borne by client by way penalty, interest etc. Our taxation advise on critical points has helped many clients to save taxes in huge amount. We help you in obtaining optimum finance at lowest cost, ensuring Government benefits and thus ultimately improving your business profits leading to your success and satisfaction.',
                },
                {
                    id: '2',
                    year: 'images/star.jpg',
                    title: 'Our Vision',
                    description: `At Prosource Advisory, we don’t just create financial solutions; we create lasting relationships with our clients. A healthy customer-enterprise relationship comes with being involved with our clients and keeping them updated with the regular developments to achieve their goals.`, 
                    secondes  :`Some values cannot be acquired.  Our innate morals, honesty and transparency set us apart. Upholding transparency and honesty are our topmost priorities, as we approach our client with professionalism and support. We are a people's venture before we're a business for you. We've only been getting better since we began. Our increasing clientele testifies this.`,
                },
                {
                    id: '3',
                    year: 'images/achivement.jpg',
                    title: 'Our Achievements',
                    description: `We have always stood with even tiny enterprise. There are so many entrepreneurs who could not start their dream business because of lack of fund but with our help they have been able to get finance from banks as well as government subsidies and today their revenues are in crores.`,
                    secondes:`Be it an entirely new project, expansion of an existing business, short term funds requirement or funding for working capital in an existing business, our Project Finance team works along with you to provide customized solutions for your business. We arrange finance from nationalized banks, Private Banks, NBFCs and even private financers as per requirement of the client. After properly studying your financials and viability of the project, we can also arrange finance in the form of equity from an investor.`,
                    lastdes:`As a financial consultant we strive to figure out optimum finance requirement of the client, evaluate various sources of availing required fund, studying and understanding various policies of financial institutions and its applicability to the client, selecting the best source of finance and ensuring lowest cost for the same. Apart from that we also study various policies of Government providing financial assistance (subsidy) to a business unit. We make full effort to see that no genuine government benefits are missed for our client.`
                }
            ]
            
        }
    }
    render() {
        return (
            <div>
                <div className="flexslider s2">
                    <div className="flat-slides">                            
                        <ul className="slides">
                            {
                                this.state.imgslide.map(data =>(
                                    <li key={data.id}>   
                                        <img src={data.srcimg} alt="img" />
                                    </li>
                                ))
                            }
                            
                        </ul> 
                    </div>
                </div> 
                    {
                        this.state.content.map(data =>(
                            <div className="box-content" key={data.id} >
                                <div className="title">{data.title}</div>
                                <p style={{fontSize:'15px',fontWeight:500}}>{data.description}</p>
                                <p style={{fontSize:'15px',fontWeight:500}}>{data.discr}</p>
                                <div className="dividers dividers-bc-v4"></div>
                            </div>
                            ))
                    }
                
                    {
                        this.state.blogtimeline.map(data =>(
                            <div className="flat-text-block-timeline" key={data.id} >
                                <div className="year"><img style={{width:'10%'}} src={data.year} loading="lazy" alt="" /></div>
                                <div className="flat-timeline-content">
                                <div className="box-content">
                                    <div className="title">{data.title}</div>
                                    <p style={{fontSize:'15px',fontWeight:500}}>{data.description}</p>
                                    <p style={{fontSize:'15px',fontWeight:500}}>{data.secondes}</p>
                                    <p style={{fontSize:'15px',fontWeight:500}}>{data.lastdes}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
								
        )
                                
    }
}

export default BoxView;