import React, { Component } from 'react'
import { Header, TopHeader, Footer, Loader } from '../layouts/general';
import { Link, withRouter } from 'react-router-dom';
// import APICall from '../../APIServices/APICall';
import axios from 'axios';


export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Home'
                }
            ],
            email: '',
            password: '',
            message : '',
            showmsg : false
        }
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePwdChange = this.handlePwdChange.bind(this);
        this.userLogin = this.userLogin.bind(this);
    }

    handleEmailChange = event => {
        this.setState({ email: event.target.value });
    };
    handlePwdChange = event => {
        this.setState({ password: event.target.value });
    };

    userLogin = (event) => {
        event.preventDefault();
        const data = { email: this.state.email, password: this.state.password }
        const headers = {
            'Content-Type': 'multipart/form-data'
        }

        const baseURL = "https://vibrantsofttech.com/project/prosourceadvisory/api/authentication/login";

        axios
            .post(baseURL, data, {
                headers: headers
            })
            .then((response) => {
                console.log("res", response.data)
               
                if (response.data.status == true) {
                    const userName = response.data.data.first_name + ' '+response.data.data.last_name
                    localStorage.setItem('user',userName)
                    localStorage.setItem('isAuthenticated',true)
                    localStorage.setItem("userdata", JSON.stringify(response.data.data));
                    window.location.href = "/profile"
                    this.setState({
                        message:response.data.message,
                        showmsg:true
                    })
                } else {
                    this.setState({
                        message:response.data.message,
                        showmsg:true
                    })
                }
            });

    }

    render() {
        return (
            <div className="bg-body3">
                <div className="boxed">
                    <Loader />
                    {/* <TopHeader /> */}
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id} />
                        ))
                    }
                    <div class="login-container">
                        <div class="login-form">
                            <div class="login-form-inner">
                                <div class="logo"> <h1 class="body-text">Welocome to Prosource!</h1>
                                </div>
                                <h1 style={{ color: 'black', fontSize: '30px' }}>Login</h1>
                                
                                {this.state.showmsg === true ? <h4 style={{ color: 'red', marginBottom:'10px' }}>{this.state.message}</h4> : ""}
                                <div class="login-form-group">
                                    <label for="email">Email <span class="required-star">*</span></label>
                                    <input onChange={this.handleEmailChange} type="text" placeholder="email@website.com" id="email" />
                                </div>
                                <div class="login-form-group">
                                    <label for="pwd">Password <span class="required-star">*</span></label>
                                    <input onChange={this.handlePwdChange} autoComplete="off" type="password" placeholder="Minimum 8 characters" id="pwd" />
                                </div>

                                <a href="#" onClick={this.userLogin} class="rounded-button login-cta">Login</a>

                                <div class="register-div">Not registered yet? <a href="#" to={'/signup'} onClick={() => { window.location.href = "/signup" }} class="link create-account" link>Create an account ?</a></div>
                            </div>

                        </div>
                        <div class="onboarding">
                            <div class="swiper-container">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide color-1">
                                        <div class="slide-image">
                                            <img src="images/login.jpg" loading="lazy" alt="" />
                                        </div>
                                    </div>

                                </div>
                                {/* <!-- Add Pagination --> */}
                                <div class="swiper-pagination"></div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>

            </div >
        )
    }
}

