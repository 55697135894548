const menus = [
    {
        id: 1,
        name: 'Home',
        linkmenu: '/'
    },
    {
        id: 2,
        name: 'About',
        linkmenu: '/about-us',
    },
    {
        id: 3,
        name: 'Services',
        linkmenu: '/loanconsultancy',
        namesub:  [
            {
                id: 1,
                sub: 'Loan Consultancy Services',
                links: '/loanconsultancy',
                submenu: undefined
            },
            {
                id: 2,
                sub: 'Subsidy Consultancy Services',
                links: '/subsidyconsultancy',
                submenu: undefined
            },
            {
                id: 3,
                sub: 'Registration / Formation Services',
                links: '/registrationformation',
                submenu: undefined
            },
            {
                id: 4,
                sub: 'Book Keeping services',
                links: '/bookkeeping',
                submenu: undefined
            },
            {
                id: 5,
                sub: 'Investment Advisory services',
                links: '/investmentadvisory',
                submenu: undefined
            },
            {
                id: 6,
                sub: 'NRI services',
                links: '/nriservice',
                submenu: undefined
            }
        ],
    },
    {
        id: 6,
        name: 'Contact',
        linkmenu: '/contact-us',

    }
]

export default menus;