import React, { Component } from 'react'
import { Link, withRouter, } from 'react-router-dom'
import menus from '../menu'
import userMenu from '../userMenu';
class HeaderWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            linklogo: '/',
            user: localStorage.getItem('user')
        }
        this.handleLogout = this.handleLogout.bind(this)
        console.log("user",this.state.user)
    }

    handleLogout = () => {
        // console.log('click')
        localStorage.clear();
        window.location.href = "/"
    }

    render() {

        const { location } = this.props;
        return (
            <div id="header" className="header bg-color style2">
                <div className="container">
                    <div className="row">
                        <div className="header-wrap" id="header-wrap">
                            <div className="col-md-12">
                                <div className="nav-wrap">
                                    <div className="btn-menu">
                                        <span></span>
                                    </div>
                                    <nav id="mainnav" className="mainnav">
                                        <ul className="menu">
                                            {
                                                menus.map(data => (
                                                    <li className={data.name === this.props.data.names ? "active" : ""} key={data.id}>
                                                        <Link to={data.linkmenu} onClick={() => { window.location.href = data.linkmenu }}>{data.name} {data.name === 'Services' ? <i className="fa fa-angle-down" aria-hidden="true"></i> : ""} </Link>
                                                        {
                                                            data.namesub === undefined ? "" :
                                                                <ul className="sub-menu">
                                                                    {
                                                                        data.namesub.map(submenus => (
                                                                            <li className={location.pathname === submenus.links || submenus.sub === this.props.data.names02 ? "active" : ""} key={submenus.id}><Link to={submenus.links} onClick={() => { window.location.href = submenus.links }}>{submenus.sub}</Link>
                                                                                {
                                                                                    submenus.submenu === undefined ? "" :
                                                                                        <ul className="sub-menu">
                                                                                            {
                                                                                                submenus.submenu.map(menusub => (
                                                                                                    <li className={location.pathname === menusub.linksub ? "active" : ""} key={menusub.id}><Link to={menusub.linksub} onClick={() => { window.location.href = menusub.linksub }}>{menusub.titlesub}</Link></li>
                                                                                                ))
                                                                                            }
                                                                                        </ul>
                                                                                }
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                        }


                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </nav>
                                </div>
                                <div className="flat-show-search">
                                    <div className="login btn btn-success" >
                                        {this.state.user !== '' ?
                                            <nav id="mainnav" className="mainnav">
                                                <ul className="menu">
                                                    {
                                                        userMenu.map(data => (
                                                            <li key={data.id}>
                                                                <Link>{this.state.user} <i className="fa fa-angle-down" aria-hidden="true"></i></Link>
                                                                <ul className="sub-menu2">
                                                                    <li>
                                                                        <Link to="/profile" onClick={() => { window.location.href = "/profile" }}>
                                                                            Profile
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={this.handleLogout}>
                                                                            Logout
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </nav>
                                            :
                                            <Link to="/login" onClick={() => { window.location.href = "/login" }} className="tp-caption sub-title color-white  btn btn-success1">Login / Signup</Link>
                                        }
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(HeaderWidget);