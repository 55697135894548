import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { Header, TopHeader, Footer, Loader, Offerslider } from '../../layouts/general';
import { Testimonials } from '../../layouts/home06'

export default class SubsidyConsultancy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMore: false,
            clickId: '',
            headers: [
                {
                    id: 1,
                    names: 'Pages'
                }
            ],
            titleheading: [
                {
                    id: '1',
                    title: 'Services'
                }
            ],
            breadcrumbs: [
                {
                    id: 1,
                    title: 'Home',
                    classicon: 'fa fa-angle-right',
                    aria: 'true'
                },
                {
                    id: 2,
                    title: 'Services',
                    classicon: 'fa fa-angle-right',
                    aria: 'true'
                }
                ,
                {
                    id: 3,
                    title: 'Subsidy Consultancy',
                    classicon: '',
                    aria: ''
                }
            ],
            titleServices: [
                {
                    id: 1,
                    title: 'Subsidy Consultancy Services',
                    description: `As MSME is the segment on which the economy of any country is based Government gives various types
                    of financial assistance (subsidy) to MSME units. This financial assistance is given by state Government as
                    well as Central Government. There are so many different schemes of the Government for different types
                    of organizations. It is very much necessary to have minute information about various schemes of
                    Government so that maximum benefit can be obtained from these schemes. A small mistake in initial
                    decision making may deny the benefit of subsidy to the organization. With vast experience of 19 years
                    our team helps businessman to see that no major subsidy benefit is left over. According to latest
                    guidelines of Government, various units are defined to be micro, small, and medium enterprise as
                    below:`,
                }
            ],
            imagebox: [
                {
                    id: '1',
                    imgsrc: 'images/imagebox/04.jpg',
                    title: 'Capital subsid',
                    description: 'We help you to obtain finance from nationalized or private financial institutions for purchase of machinery either to start a new operation or to modernize/expand your existing operations.',
                    secondDescription: 'With the continuous evolution of technology, it has become imperative for manufacturing enterprises to have the latest equipment and tools that are vital to their operations. Prolonged use of obsolete machinery can be detrimental to business interests and presents an opportunity for competitors to overtake. If your operations are in need of better machines and kits to improve productivity and product quality, do not let the high costs deter you from investing in them.Financial institutions generally offer loan upto 75% of cost of machinery, tools and other accessories.  This loan can be availed for the period of 5 years or 7 years. Means one can repay this term loan by 60 monthly installments or 84 monthly installments. Rate of interest on loan varies from 9% to 11% depending on profile and rating of the borrower and policy of the bank. Our team Analyze your need and accordingly suggest the best possible options of Finance.'
                },
                {
                    id: '2',
                    imgsrc: 'images/imagebox/05.jpg',
                    title: 'Interest Subsidy',
                    description: 'A businessman requires finance for purchase of factory building/shed or for construction of factory building. Various institutions and banks provide finance varying from 60% to 80% of cost of building'
                },
                {
                    id: '3',
                    imgsrc: 'images/imagebox/06.jpg',
                    title: 'Food industry subsidy',
                    description: 'Analysing requirement of the organization and studying financial documents we can help to obtain required working capital finance in the form of CC (Cash credit), LC ( letter of credit), OD (over draft), BG ( Bank guarantee)'
                },
                {
                    id: '4',
                    imgsrc: 'images/imagebox/07.jpg',
                    title: 'Rent Subsidy',
                    description: 'As MSME is the segment on which the economy of any country is based Government gives various types of financial assistance (subsidy) to MSME units.'
                },
                {
                    id: '5',
                    imgsrc: 'images/imagebox/08.jpg',
                    title: 'Other Government Benefits',
                    description: 'Mutual funds pool money from many investors to purchase broad range of investments, such as stocks.'
                }
            ],
            imagebox2: [
                {
                    id: '1',
                    imgsrc: 'images/imagebox/04.jpg',
                    title: 'Expert in all type of subsidy',
                },
                {
                    id: '2',
                    imgsrc: 'images/imagebox/05.jpg',
                    title: 'Benefits from Central & State Government',
                },
                {
                    id: '3',
                    imgsrc: 'images/imagebox/06.jpg',
                    title: 'One Representative allocation during whole process',
                }

            ]
        }
        const isAuthenticated = localStorage.getItem('isAuthenticated')
        if(isAuthenticated === true){
            setInterval(() => {
                localStorage.setItem('isAuthenticated', false);
                window.location.reload(false);
              }, 300000);
        }
    }

    showMoreDiscripation = (id) => {
        console.log("id", id);
        this.setState({ clickId: id })
        this.setState({ showMore: !this.state.showMore })

    }

    render() {
        return (
            <div className="bg-body3">
                <div className="boxed">
                    <Loader />
                    {/* <TopHeader /> */}
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id} />
                        ))
                    }

                    <Offerslider />

                    <section className="flat-row pd-services-post">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="title-section center s1"  >
                                        <h2>Why Us</h2>

                                    </div>
                                    <div className="dividers dividers-imagebox"></div>
                                </div>
                                <section className="flat-row pd-services-post">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="wrap-imagebox-grid">
                                                    {
                                                        this.state.imagebox2.map(data => (
                                                            <div className="flat-imagebox services-grid item" key={data.id} >
                                                                <div>
                                                                    <div class="rfcard">
                                                                        <div class="col-md-4">
                                                                            <div class="box2" style={{ backgroundImage: "url(" + data.imgsrc + ")", }}>
                                                                                <div class="hover">
                                                                                    <h1>{data.title}</h1>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        ))
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    {
                                        this.state.titleServices.map(data => (
                                            <div className="title-section center s1" key={data.id} >
                                                <h2>{data.title} </h2>
                                                <p className="sub-title-section">{data.description} </p>
                                            </div>
                                        ))
                                    }
                                    <div className="dividers dividers-imagebox"></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="wrap-imagebox-grid">
                                        {
                                            this.state.imagebox.map(data => (
                                                <div className="col-md-4">
                                                    <div class="card1">
                                                        <div className="box">
                                                            <div className="content">
                                                                <h2>{data.id}</h2>
                                                                <h3>{data.title}</h3>
                                                                <p style={{ fontSize: '17px' }}>{data.description}</p>
                                                                {/* {data.id === this.state.clickId ? this.state.showMore ? <p>{data.secondDescription}</p> : "" : ""}
                                                            <div className="flat-imagebox-button">
                                                                <Link onClick={() => this.showMoreDiscripation(data.id)} to="#">Read More</Link>
                                                            </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    {/* <Testimonials /> */}

                    <Footer />
                </div>
            </div>
        );
    }
}
