import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { Header, TopHeader, Footer, Loader, Offerslider } from '../../layouts/general';
import { Testimonials } from '../../layouts/home06'

export default class BookKeeping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Pages'
                }
            ],
            titleheading: [
                {
                    id: '1',
                    title: 'Services'
                }
            ],
            breadcrumbs: [
                {
                    id: 1,
                    title: 'Home',
                    classicon: 'fa fa-angle-right',
                    aria: 'true'
                },
                {
                    id: 2,
                    title: 'Services',
                    classicon: 'fa fa-angle-right',
                    aria: 'true'
                }
                ,
                {
                    id: 3,
                    title: 'Book Keeping service',
                    classicon: '',
                    aria: ''
                }
            ],
            titleServices: [
                {
                    id: 1,
                    title: 'Book Keeping Services',
                    description: 'Prosource Advisory aim to provide customized financial solutions to our clients according to their requirements. We depend on our rich and diverse expertise, industry experience, commitment to integrity and ethics and personalized solutions. We are armed with a team of highly qualified and expert professionals to aid you in intelligent decision making. ',
                }
            ],
            main: [
                {
                    id: '1',
                    imgsrc: 'images/imagebox/01.jpg',
                    title: 'Accounting Service',
                    description: 'We help you to obtain finance from nationalized or private financial institutions for purchase of machinery either to start a new operation or to modernize/expand your existing operations.'
                },
                {
                    id: '2',
                    imgsrc: 'images/imagebox/02.jpg',
                    title: 'GST Registration and advisory service',
                    description: 'A businessman requires finance for purchase of factory building/shed or for construction of factory building. Various institutions and banks provide finance varying from 60% to 80% of cost of building'
                },
                {
                    id: '3',
                    imgsrc: 'images/imagebox/03.jpg',
                    title: 'Income tax',
                    description: 'Analysing requirement of the organization and studying financial documents we can help to obtain required working capital finance in the form of CC (Cash credit), LC ( letter of credit), OD (over draft), BG ( Bank guarantee)'
                },
                {
                    id: '4',
                    imgsrc: 'images/imagebox/07.jpg',
                    title: 'TDS service',
                    description: 'As MSME is the segment on which the economy of any country is based Government gives various types of financial assistance (subsidy) to MSME units.'
                }
            ],
            imagebox2: [
                {
                    id: '1',
                    imgsrc: 'images/imagebox/04.jpg',
                    title: 'Good team of accountants for each client',
                },
                {
                    id: '2',
                    imgsrc: 'images/imagebox/05.jpg',
                    title: ' Supervised by CA/Senior accountant',
                },
                {
                    id: '3',
                    imgsrc: 'images/imagebox/06.jpg',
                    title: 'Packages start with competitive rates',
                }

            ]
        }
        const isAuthenticated = localStorage.getItem('isAuthenticated')
        if(isAuthenticated === true){
            setInterval(() => {
                localStorage.setItem('isAuthenticated', false);
                window.location.reload(false);
              }, 300000);
        }
    }
    render() {
        return (
            <div className="bg-body3">
                <div className="boxed">
                    <Loader />
                    {/* <TopHeader /> */}
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id} />
                        ))
                    }

                    <Offerslider />

                    <section className="flat-row pd-services-post">
                        <div className="container">
                        <div className="row">
								<div className="col-md-12">
									<div className="title-section center s1"  >
										<h2>Why Us</h2>

									</div>
									<div className="dividers dividers-imagebox"></div>
								</div>
								<section className="flat-row pd-services-post">
									<div className="container">
										<div className="row">
											<div className="col-md-12">
												<div className="wrap-imagebox-grid">
													{
														this.state.imagebox2.map(data => (
															<div className="flat-imagebox services-grid item" key={data.id} >
																<div>
																	<div class="rfcard">
																		<div class="col-md-6">
																			<div class="box2" style={{ backgroundImage: "url(" + data.imgsrc + ")", }}>
																				<div class="hover">
																					<h1>{data.title}</h1>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>


														))
													}

												</div>
											</div>
										</div>
									</div>
								</section>
							</div>
                            <div className="row">
                                <div className="col-md-12">
                                    {
                                        this.state.titleServices.map(data => (
                                            <div className="title-section center s1" key={data.id} >
                                                <h2>{data.title} </h2>
                                                {/* <p className="sub-title-section">{data.description} </p> */}
                                            </div>
                                        ))
                                    }
                                    <div className="dividers dividers-imagebox"></div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="wrap-imagebox-grid">
                                        {
                                            this.state.main.map(data => (
                                                <div className="flat-imagebox services-grid item" key={data.id} >
                                                    <div className="flat-imagebox-inner"  >
                                                        <div className="flat-imagebox-image">
                                                            <img src={data.imgsrc} alt="img" />
                                                        </div>
                                                        <div className="flat-imagebox-header">
                                                            <h3 className="flat-imagebox-title">
                                                                <Link to="#">{data.title}</Link>
                                                            </h3>
                                                        </div>
                                                        <div className="flat-imagebox-content">
                                                            <div className="flat-imagebox-desc">{data.description}</div>
                                                            {data.id === this.state.clickId ? this.state.showMore ? <div className="flat-imagebox-desc">{data.secondDescription}</div> : "" : ""}
                                                            <div className="flat-imagebox-button">
                                                                <Link to="#">Read More</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* <div className="wrap-imagebox-grid">
                                {
                                    this.state.main.map(data => (
                                        <div className="row">
                                            <div className="col-md-12">
                                            {data.id % 2 == 0 ? <div className="col-md-6"></div> : ""} 
                                                  

                                                <div className="col-md-6">
                                                    <ol class="ol-cards alternate" >
                                                        <li style={{ "--ol-cards-color-accent": "#18ba60" }}>
                                                            <div class="step"><span>0{data.id}</span></div>
                                                            <div class="title">{data.title}</div>
                                                            <div class="content">{data.description}</div>
                                                        </li>
                                                    </ol>
                                                </div>
                                                {data.id % 2 != 0 ? <div className="col-md-6"></div> : ""} 
                                                
                                            </div>
                                            
                                        </div>

                                    ))
                                }

                            </div> */}

                        </div>
                    </section>


                    {/* <Testimonials /> */}

                    <Footer />
                </div>
            </div>
        );
    }
}
