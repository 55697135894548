import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { Header, TopHeader, Footer, Loader } from '../layouts/general';
import axios from 'axios';
// import Management from '../layouts/general/services/Management';
// import { Testimonials } from '../layouts/home06'

export default class Profile extends Component {

    fileObj = [];
    fileArray = [];

    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Pages'
                }
            ],
            titleheading: [
                {
                    id: '1',
                    title: 'Services Grid'
                }
            ],
            breadcrumbs: [
                {
                    id: 1,
                    title: 'Home',
                    classicon: 'fa fa-angle-right',
                    aria: 'true'
                },
                {
                    id: 2,
                    title: 'Services',
                    classicon: 'fa fa-angle-right',
                    aria: 'true'
                },
                {
                    id: 3,
                    title: 'Subsidy Consultancy',
                    classicon: '',
                    aria: ''
                }
            ],
            itembox: [
                {
                    id: 1,
                    title: 'Wealth Management',
                    classicon: 'icon-benefit',
                    boxcontent: 'Enim ad minim veniam, quis nostrud exercitation ullamco laboris. Quis nostrud exercitation'
                },
            ],
            menutab: [
                {
                    id: 1,
                    title: 'Upload Document',
                    class: 'active'
                },
                {
                    id: 2,
                    title: 'Profile',
                    // class: 'active'
                }

            ],
            titletab: [
                {
                    id: 1,
                    title: 'Our Brochure',
                    description: 'View our 2016 financial prospectus brochure for an easy to read guide on all of the services offered.'
                }
            ],
            tabpost: [
                {
                    id: '1',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '2',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '3',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '4',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '5',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '6',
                    classcategory: 'wrap-one-half services',
                },
            ],
            boxcontent: [
                {
                    id: '1',
                    title: 'Equity',
                    description: "We also help MSMEs who are willing to accept fund for their business in the form of capital. Those entrepreneurs who are ready to accept investor as partner also can approach us. We have large number of investors who are ready to invest in good big project in the form of equity. Those units which have become NPA and require fund to repay their bank debt by way of diluting their stake in their own enterprise can also contact us. After studying your financial papers and viability of the project we can help to find out financial partner.",
                },
                {
                    id: '2',
                    title: 'Builder Finance',
                    description: 'We also help builders to obtain finance from nationalized/ private banks for construction of residential or commercial complex. Generally banks provide finance to builders who have completed atleast 3 projects successfully. Builder is required to have his own land and finance is available for construction of the project. Funds can be arranged from private banks on unsold stock of the existing scheme or by way of mortgage of commercial plot of land.',
                }
                // ,
                // {
                //     id: '3',
                //     title: 'Government Subsidy',
                //     description: 'As MSME is the segment on which the economy of any country is based Government gives various types of financial assistance (subsidy) to MSME units. This financial assistance is given by state Government as well as Central Government. There are so many different schemes of the Government for different types of organizations. It is very much necessary to have minute information about various schemes of Government so that maximum benefit can be obtained from these schemes.  A small mistake in initial decision making may deny the benefit of subsidy to the organization. With vast experience of 19 years our team helps businessman to see that no major subsidy benefit is left over.  According to latest guidelines of Government, various units are defined to be micro, small, and medium enterprise as below:',
                // },
            ],
            flattoggle: [
                {
                    id: '1',
                    title: 'What is Government Subsidy ?',
                    content: 'As MSME is the segment on which the economy of any country is based Government gives various types of financial assistance (subsidy) to MSME units.This financial assistance is given by state Government as well as Central Government. There are so many different schemes of the Government for different types of organizations',
                    classtoggle: 'toggle-title active'
                },
                {
                    id: '2',
                    title: 'What is Equity?',
                    content: 'We also help MSMEs who are willing to accept fund for their business in the form of capital. Those entrepreneurs who are ready to accept investor as partner also can approach us. We have large number of investors who are ready to invest in good big project in the form of equity. Those units which have become NPA and require fund to repay their bank debt by way of diluting their stake in their own enterprise can also contact us. After studying your financial papers and viability of the project we can help to find out financial partner.',
                    classtoggle: 'toggle-title'
                },
                {
                    id: '3',
                    title: 'Builder Finance',
                    content: 'We also help builders to obtain finance from nationalized/ private banks for construction of residential or commercial complex. Generally banks provide finance to builders who have completed atleast 3 projects successfully. Builder is required to have his own land and finance is available for construction of the project. Funds can be arranged from private banks on unsold stock of the existing scheme or by way of mortgage of commercial plot of land.',
                    classtoggle: 'toggle-title'
                },
            ],
            name: '',
            files: [],
            urls: [],
            isDragging: false,
            documentName: 'null',
            nvalid: false,
            dvalid: false,
            data: false,
            pmsg: false,
            dmsg: false,
            ddmsg: false,
            userDetails: JSON.parse(localStorage.getItem("userdata")),
            fname: JSON.parse(localStorage.getItem("userdata")).first_name,
            lname: JSON.parse(localStorage.getItem("userdata")).last_name,
            phone: JSON.parse(localStorage.getItem("userdata")).phone,
            documentData: [],
        }
        this.onChange = this.onChange.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleDragEnter = this.handleDragEnter.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDragLeave = this.handleDragLeave.bind(this);
        this.handleFiles = this.handleFiles.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.handlefnameChange = this.handlefnameChange.bind(this);
        this.handlelnameChange = this.handlelnameChange.bind(this);
        this.handlephoneChange = this.handlephoneChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.getUpdatedData();
        this.getDocumentList();
        const isAuthenticated = localStorage.getItem('isAuthenticated')
        if(isAuthenticated === true){
            setInterval(() => {
                localStorage.setItem('isAuthenticated', false);
                window.location.href = "/login"
              }, 300000);
        }
        
    }

    onRemove(index) {
        const baseURL = "https://vibrantsofttech.com/project/prosourceadvisory/api/authentication/user_document/";
        const headers = {
            'Content-Type': 'multipart/form-data'
        }
        const data = {
            user_id: this.state.userDetails.id,
            id: index
        }
        axios
            .post(baseURL, data, {
                headers: headers
            })
            .then((response) => {
                console.log("res", response.data)
                if (response.data.status === true) {
                    this.setState({
                        ddmsg: true
                    })
                }

                setTimeout(() => {
                    this.setState({
                        ddmsg: false
                    })
                }, 5000);
                this.getDocumentList()
            });
        // var { files, urls } = this.state;
        // let newFiles = files.filter((file, i) => i !== index);
        // let newUrls = urls.filter((url, i) => i !== index);

        // this.setState({
        //     ...this.state,
        //     files: newFiles,
        //     urls: newUrls
        // });
    }

    handleDrags(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            ...this.state,
            isDragging: true
        });
    }

    handleDragEnter(e) {
        this.handleDrags(e);
    }

    handleDragOver(e) {
        this.handleDrags(e);
    }

    handleDragLeave(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            ...this.state,
            isDragging: false
        });
    }

    onChange(e) {
        e.preventDefault()
        const files = e.target.files;
        console.log("file", files);
        [].forEach.call(files, this.handleFiles);
    }

    handleDrop(e) {
        e.stopPropagation();
        e.preventDefault();

        const data = e.dataTransfer;
        const files = data.files;
        console.log("Oops...you dropped this: ", files);

        [].forEach.call(files, this.handleFiles);

        this.setState({
            ...this.state,
            isDragging: false
        });
    }

    nameChange(e) {
        this.setState({ name: e.target.value })

    }

    handleFiles(file) {

        // this could be refactored to not use the file reader
        // const documentname = this.state.name
        var reader = new FileReader();
        reader.onloadend = () => {

            var imageUrl = window.URL.createObjectURL(file);

            this.setState({
                files: [file, ...this.state.files],
                urls: [imageUrl, ...this.state.urls],
            });
        }

        reader.readAsDataURL(file);
    }

    handlefnameChange = (event) => {
        this.setState({ fname: event.target.value });
    };
    handlelnameChange = (event) => {
        this.setState({ lname: event.target.value });
    };
    handlephoneChange = (event) => {
        this.setState({ phone: event.target.value });
    };

    handleSave() {
        // e.preventDefault()
        const documentname = this.state.name
        const baseURL = "https://vibrantsofttech.com/project/prosourceadvisory/api/authentication/uploadimg/";

        const formData = new FormData();
        formData.append('file', this.state.files[0])
        formData.append('id', this.state.userDetails.id)
        formData.append('name', documentname)

        const headers = {
            'Content-Type': 'multipart/form-data'
        }

        if (documentname === '' && this.state.urls.length === 0) {
            this.setState({
                dvalid: true,
                nvalid: true
            })

        }
        else {

            switch (true) {
                case documentname === '':
                    this.setState({
                        nvalid: true
                    })
                    break;
                case this.state.urls.length === 0:
                    this.setState({
                        dvalid: true,
                        nvalid: false
                    })
                    break;
                default:
                    this.setState({
                        dvalid: false,
                        nvalid: false,
                        documentName: documentname,
                        data: true
                    })
                    axios
                        .post(baseURL, formData, {
                            headers: headers
                        })
                        .then((response) => {
                            console.log("res1", response.data)
                            if (response.data.status === true) {
                                this.setState({
                                    dmsg: true,
                                })
                            }

                            setTimeout(() => {
                                this.setState({
                                    dmsg: false
                                })
                            }, 5000);
                            this.getDocumentList()
                        });
                    break;
            }

        }
    }

    handleUpdate = (e) => {
        e.preventDefault();

        const baseURL = "https://vibrantsofttech.com/project/prosourceadvisory/api/authentication/user/";
        const headers = {
            'Content-Type': 'multipart/form-data'
        }
        const data = {
            id: this.state.userDetails.id,
            first_name: this.state.fname,
            last_name: this.state.lname,
            email: this.state.userDetails.email,
            password: this.state.userDetails.password,
            phone: this.state.phone
        }
        axios.post(baseURL, data, {
            headers: headers
        })
            .then((response) => {
                console.log("res", response.data)
                if (response.data.status === true) {
                    this.setState({
                        pmsg: true
                    })
                    setTimeout(() => {
                        this.setState({
                            pmsg: false
                        })
                    }, 10000);
                }
            });

    }

    getUpdatedData = () => {
        const baseURL = "https://vibrantsofttech.com/project/prosourceadvisory/api/authentication/user/";
        const headers = {
            'Content-Type': 'multipart/form-data'
        }
        axios.get(baseURL + this.state.userDetails.id).then((res) => {
            this.setState({
                fname: res.data.first_name,
                lname: res.data.last_name,
                phone: res.data.phone
            })
        })

    }

    getDocumentList = (e) => {
        const baseURL = "https://vibrantsofttech.com/project/prosourceadvisory/api/authentication/user_document/";
        // const headers = {
        //     'Content-Type': 'multipart/form-data'
        // }
        axios.get(baseURL + this.state.userDetails.id).then((res) => {
            console.log("res", res.data.data);
            this.setState({
                documentData: res.data.data
            })
        })
    }

    render() {
        const { urls, files, isDragging } = this.state;
        const dropClass = isDragging ? "dragDrop dragging" : "dragDrop";

        return (
            <div className="bg-body3">
                <div className="boxed">
                    <Loader />
                    {/* <TopHeader /> */}
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id} />
                        ))
                    }

                    <div className="page-title">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="page-title-heading">
                                        <h1 className="h1-title">Profile</h1>
                                    </div>
                                    {/* <ul className="breadcrumbs">
                                        {
                                            this.state.breadcrumbs.map(data =>(
                                                <li key={data.id} ><Link to="#" title="">{data.title}<i className={data.classicon} aria-hidden={data.aria}></i></Link></li>
                                            ))
                                        }
                                    </ul> */}
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <Management /> */}

                    <section className="flat-row pd-services-widget">
                        <div className="container">
                            <div className="row flat-tabs" data-effect="fadeIn">
                                <div className="col-md-3">
                                    <div className="sidebar left">
                                        <aside className="widget widget_nav_menu">
                                            <div className="menu-services-container">
                                                <ul className="menu menu-tab">
                                                    {
                                                        this.state.menutab.map(data => (
                                                            <li className={data.class} key={data.id} ><Link to="#">{data.title}</Link></li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </aside>
                                    </div>
                                </div>
                                {/* <MainPost /> */}
                                <div className="col-md-9 content-tab">
                                    {
                                        this.state.menutab.map(data => (
                                            <div className="content-inner" key={data.id} >
                                                <div className={data.classcategory}>
                                                    <div className="one-half1 v1">
                                                        <div className="box-content">
                                                            {this.state.dmsg === true ? <label style={{ color: '#18ba60', paddingBottom: '10px', fontSize: '25px' }}>Documents uploaded successfully!</label> : ''}
                                                            {this.state.ddmsg === true ? <label style={{ color: '#18ba60', paddingBottom: '10px', fontSize: '25px' }}>Document Deleted successfully!</label> : ''}
                                                            {data.id === 1 ?
                                                                <div key={this.state.boxcontent.id} >
                                                                    <div className="title">Upload Documents</div>
                                                                    <div>
                                                                        <div className="uploadInput" >
                                                                            <input type="text"
                                                                                placeholder="Enter Document Name"
                                                                                required
                                                                                onChange={this.nameChange}
                                                                            />

                                                                            {this.state.nvalid === true ? <label style={{ color: 'red', paddingBottom: '10px' }}>Please enter document name</label> : ""}

                                                                            <input type="file"
                                                                                onChange={this.onChange}
                                                                                accept="image/*"
                                                                                multiple
                                                                            />
                                                                            {urls && (urls.map((url, i) => (<p style={{ color: 'black' }}>{files[i].name}</p>)))}
                                                                            <div className={dropClass}
                                                                                onDrop={this.handleDrop}
                                                                                onDragOver={this.handleDragOver}
                                                                                onDragEnter={this.handleDragEnter}
                                                                                onDragLeave={this.handleDragLeave} >
                                                                                <div className="inside">
                                                                                    <span>Drop files here</span>
                                                                                    <div>
                                                                                        <i className="material-icons">file_upload</i>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {this.state.dvalid === true ? <label style={{ color: 'red', paddingBottom: '10px' }}>Please select documents</label> : ""}
                                                                            <button style={{ marginTop: '10px', display: 'flex' }} onClick={this.handleSave}>Save</button>
                                                                        </div>


                                                                        {this.state.documentData !== undefined ?
                                                                            <table class="table table-striped uplddiv" width="100%">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col">Name</th>
                                                                                        <th scope="col">download</th>
                                                                                        <th scope="col">Date/Time</th>

                                                                                        <th scope="col">Action</th>
                                                                                    </tr>
                                                                                </thead>

                                                                                <tbody>
                                                                                    {
                                                                                        (this.state.documentData.map((data, i) => (


                                                                                            <tr>
                                                                                                <td>{data.name}</td>
                                                                                                <td><a href={data.file_path + '/' + data.file_name} download>{data.name}</a></td>
                                                                                                <td>{data.created}</td>
                                                                                                {/* <td>{files[i].size.toLocaleString()} KBs</td> */}

                                                                                                <td><button className="btnicon" data-toggle="modal" data-target="#exampleModalCenter"> <i className="material-icons"
                                                                                                >delete</i></button></td>


                                                                                                {/* <!-- Modal --> */}
                                                                                                <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                                                                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                                                                                        <div class="modal-content">
                                                                                                            <div class="modal-header">
                                                                                                                <h5 class="modal-title" id="exampleModalLongTitle">Delete</h5>
                                                                                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                                                                    <span aria-hidden="true">&times;</span>
                                                                                                                </button>
                                                                                                            </div>
                                                                                                            <div class="modal-body">
                                                                                                                Are you sure you want to delete this ?
                                                                                                            </div>
                                                                                                            <div class="modal-footer">
                                                                                                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                                                                                                <button onClick={() => this.onRemove(data.id)} data-dismiss="modal" type="button" class="btn btn-primary">Confirm</button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </tr>


                                                                                        )))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                            :
                                                                            <div>
                                                                                <label style={{ color: '#18ba60', paddingBottom: '10px', fontSize: '30px',marginTop:'100px',marginLeft:'100px' }}>No Documents Found!</label>
                                                                            </div>}






                                                                        <div>

                                                                        </div>
                                                                    </div>
                                                                    <div className="dividers dividers-bc-v1"></div>
                                                                </div>
                                                                :
                                                                <div key={this.state.boxcontent.id} >

                                                                    <div class="profile resposive">

                                                                        {this.state.pmsg === true ? <label style={{ color: '#18ba60', paddingBottom: '10px', fontSize: '25px' }}>Your profile is updated!</label> : ''}
                                                                        <div class="profile-header">
                                                                            <div class="profile-info">
                                                                                <div class="profile-img">
                                                                                    <img src="https://i0.wp.com/www.winhelponline.com/blog/wp-content/uploads/2017/12/user.png?fit=256%2C256&quality=100&ssl=1" />
                                                                                </div>
                                                                                <div className="dprofile">
                                                                                    <label class="profile-username">First Name</label>
                                                                                    <input className="pinput" type="text" value={this.state.fname} onChange={this.handlefnameChange} placeholder="Enter First Name" id="fname" />
                                                                                </div>
                                                                                <div className="dprofile">
                                                                                    <label class="profile-username">Last Name</label>
                                                                                    <input className="pinput" type="text" value={this.state.lname} onChange={this.handlelnameChange} placeholder="Enter First Name" id="fname" />
                                                                                </div>
                                                                                <div className="dprofile">
                                                                                    <label class="profile-username">Email</label>
                                                                                    <input value={this.state.userDetails.email} disabled className="pinput1" type="text" />
                                                                                </div>
                                                                                <div className="dprofile">
                                                                                    <label class="profile-username">Phone</label>
                                                                                    <input value={this.state.phone} id="number" onChange={this.handlephoneChange} className="pinput2" type="text" />
                                                                                </div>
                                                                                <button onClick={this.handleUpdate} className='btnupdate'>Update</button>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        ))
                                    }

                                </div>
                            </div>
                        </div>
                    </section>


                    <Footer />
                </div>
            </div>
        );
    }
}
