import Home06 from './Home06'
import OverView from './OverView'
import Contact01 from './Contact01'
import LoanConsultancy from './AllServices/LoanConsultancy'
import SubsidyConsultancy from './AllServices/SubsidyConsultancy'
import RegistrationFormation from './AllServices/RegistrationFormation'
import BookKeeping from './AllServices/BookKeeping'
import InvestmentAdvisory from './AllServices/InvestmentAdvisory'
import NRI from './AllServices/Nri'
import Login from './Login'
import Signup from './Signup'
import Profile from './Profile'
import Authentication from './Authentication'

const routes = [
    { path: '/', component: Home06},
    { path: '/loanconsultancy', component: LoanConsultancy},
    { path: '/subsidyconsultancy', component: SubsidyConsultancy},
    { path: '/registrationformation', component: RegistrationFormation},
    { path: '/bookkeeping', component: BookKeeping},
    { path: '/investmentadvisory', component: InvestmentAdvisory},
    { path: '/nriservice', component: NRI},
    { path: '/about-us', component: OverView},
    { path: '/contact-us', component: Contact01},
    { path: '/profile',component:Profile},
    { path: '/login', component: Login},
    { path: '/authentication', component: Authentication},
    { path: '/signup', component: Signup},  
]

export default routes;