import React, { Component } from 'react';

class TextMainFooter extends Component {
    render() {
        return (
            <div className="widget widget-box">
                <p style={{ color: 'white', fontSize: '16px' }}>Prosource Advisory aim to provide customized financial solutions to our clients according to their requirements. 
                    We depend on our rich and diverse expertise, industry experience, 
                commitment to integrity and ethics and personalized solutions. 
                We are armed with a team of highly qualified and expert professionals to aid you in intelligent decision making.
                </p>	
            </div>
            /* <!-- /.widget-text --> */
        );
    }
}

export default TextMainFooter;