const userMenu =[
    {
        id: 1,
        name: 'Profile',
        namesub:  [
            {
                id: 1,
                sub: 'Profile',
                links: '/project/prosource/profile',
                submenu: undefined
            },
            {
                id: 2,
                sub: 'Logout',
                links: '#',
                submenu: undefined
            }
        ],
    },
]
export default userMenu;